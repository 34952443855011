import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: false,
  reducers: {
    toggleLoading: (state) => !state
  },
});
export const selectLoader = state => state.loader;
export const { toggleLoading } = loaderSlice.actions;
export default loaderSlice.reducer;