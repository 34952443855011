
const STORAGE_KEY = "__USERDATA";
const ACCESS_TOKEN = "__ACCESSTOKEN";
let userData;
const CommonServices = {
    isAuthenticated: false,
    serverUrl() {
        // return "http://127.0.0.1:8000/";
        return "https://lendingplatform.uk/admin/";
        // return "http://192.168.18.7:8000/";
    },
    IsUserLoggedIn() {
        if (localStorage.getItem(STORAGE_KEY) === null && localStorage.getItem(ACCESS_TOKEN) === null) {
            return this.isAuthenticated = false;
        }
        else {
            return this.isAuthenticated = true;
        }
    },
    getUserData() {
        if (userData) {
            return userData || {};
        } else {
            var data = JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");
            userData = data;
            return data;
        }
    },
    getUserId() {
        return this.getUserData().id;
    },
    getFullName() {
        return this.getUserData().name;
    },
    getEmail() {
        return this.getUserData().email;
    },
    getUserType() {
        return this.getUserData().usertype;
    },
    isProfileCompleted() {
        return this.getUserData().is_profile === 1 ? true : false;
    },
    isUnderstandingCompleted() {
        return this.getUserData().is_understanding === 1 ? true : false;
    },
    isIdentificationCompleted() {
        return this.getUserData().is_identification === 1 ? true : false;
    },
    isPaymentCompleted() {
        return this.getUserData().is_payment === 1 ? true : false;
    },
    ExecuteGet(request, callback) {
        fetch(this.serverUrl() + "api/" + request, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                if (callback) {
                    callback(response);
                }
            }).catch((response) => {
                if (response.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/";
                }
                if (callback) {
                    callback(response);
                }
            });
    },
    ExecutePost(request, postdata, callback) {
        fetch(this.serverUrl() + "api/" + request, {
            method: "Post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postdata)
        }).then(response => response.json())
            .then(response => {
                if (callback) {
                    callback(response);
                }
            }).catch((response) => {
                if (response.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/";
                }
            });
    },
};
export default CommonServices;