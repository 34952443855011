import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
    height: '100vh',
    width: '100vw',
    backgroundColor: '#ffffff',
    display: 'flex',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 12,
    top: 0,
    left: 0,
    '& img': {
        height: 300
    }
}));

function Loader(props) {
    return (
        <Root style={{ display: props.allowLoader ? "" : "none" }}>
            <img src="/assets/images/loader.gif" className="img-fluid" alt="Loader" />
        </Root>
    );
}
export default Loader;