import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import CommonServices from "../../services/CommonServices";

const initialState = {
    walletAmount: 0,
    status: 'idle',
    error: null,
    initialLoad: true
};

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        // add(state, action) {
        //     state.walletAmount += parseInt(action.payload);
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWalletAmount.fulfilled, (state, action) => {
                state.walletAmount = action.payload;
                state.initialLoad = false;
            })
            .addCase(addWalletAmount.fulfilled, (state, action) => {
                state.walletAmount += action.payload;
            })
    },
});

export const { add } = walletSlice.actions;
export default walletSlice.reducer;

export const fetchWalletAmount = createAsyncThunk(
    'wallet/fetchWalletAmount',
    //USING AXIOS
    // async (url) => {
    //     const response = await axios.get(`${CommonServices.serverUrl()}api/${url}/${CommonServices.getUserId()`);
    //     return response.data.result;
    // }
    //USING FETCH
    async (url, thunkAPI) => {
        try {
            const response = await fetch(`${CommonServices.serverUrl()}api/${url}/${CommonServices.getUserId()}`);
            const data = await response.json();
            return data.result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addWalletAmount = createAsyncThunk(
    'wallet/addWalletAmount',
    //USING FETCH
    async (postData, thunkAPI) => {
        const { url } = postData;
        delete postData.url;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        };
        try {
            const response = await fetch(`${CommonServices.serverUrl()}api/${url}`, options);
            const responseData = await response.json();
            // if (responseData.status == 200)
            //     return parseInt(postData.amount);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
    //USING AXIOS
    // async (postData) => {
    //     const { url } = postData;
    //     const response = await axios.post(`${CommonServices.serverUrl()}api/${url}`, postData);
    //     // if (response.status == 200)
    //     //     return parseInt(postData.amount);
    // }
);