import RoutesDefinition from './shell/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme } from './shell/theme';
import Paper from '@mui/material/Paper';
import { Provider } from 'react-redux';
import Store from './store/Store';

function App() {
  return (
    <Provider store={Store}>
      <Paper elevation={0} id="AppWrapper">
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Router>
            <RoutesDefinition />
           </Router>
        </ThemeProvider>
      </Paper>
    </Provider>
  );
}

export default App;
