import { configureStore } from "@reduxjs/toolkit";
import WalletReducer from "./slices/WalletSlice";
import LoaderReducer from "./slices/LoaderSlice"

const store = configureStore({
    reducer: {
        wallet: WalletReducer,
        loader: LoaderReducer
    }
});

export default store;