import { createTheme } from '@mui/material/styles';
const theme = createTheme();
export const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#107dc0',
            light: '#f1f3f7',
            dark: '#003478'
        },
        secondary: {
            main: '#263042',
            dark: '#162435',
            light: '#363f4f'
        },
        background: {
            default: '#f9f9f9'
        },
        success: {
            main: '#71a72b'
        },
        danger: {
            main: '#ea4d54',
            light: '#fdeeee'
        },
        other: {
            gray: '#D2D2D2',
            darkgray: '#707070',
            lightgray: '#eee',
            dark: '#333',
            white: '#fff',
            green: '#3197a5'
        },
        mix: {
            gradient: 'linear-gradient(180deg, rgba(16,126,192,1) 0%, rgb(112 144 185) 85%);'
        }
    },

    typography: {
        fontFamily: ['Barlow', '"Segoe UI"', 'sans-serif'].join(','),
        h1: {
            fontSize: "2.125rem", //34PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h2: {
            fontSize: "2rem", //32PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h3: {
            fontSize: "1.875rem", //30PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h4: {
            fontSize: "1.75rem", //28PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h5: {
            fontSize: "1.5rem", //24PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h6: {
            fontSize: "1.375rem", //22PX
            fontWeight: 'bold',
            lineHeight: 1
        },
        subtitle1: {
            fontSize: "1.25rem", //20PX
            [theme.breakpoints.down("sm")]: {
                lineHeight: 1.25
            }
        },
        subtitle2: {
            fontSize: "1.125rem" //18PX
        },
    },

    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 576px)': {
                        maxWidth: 540,
                        paddingLeft: 20
                    },
                    '@media (min-width: 768px)': {
                        maxWidth: 720,
                        paddingLeft: 0
                    },
                    '@media (min-width: 992px)': {
                        maxWidth: 960,
                        paddingLeft: 0
                    },
                    '@media (min-width: 1200px)': {
                        maxWidth: 1200,
                        paddingLeft: 0
                    },
                    '@media (min-width: 1400px)': {
                        maxWidth: 1320,
                        paddingLeft: 0
                    },
                    '@media (min-width: 1461px)': {
                        maxWidth: 1400,
                        paddingLeft: 0
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    letterSpacing: 1,
                    position: 'relative',
                    color: '#323a45',
                    '&.MuiTypography-gutterBottom': {
                        marginBottom: 16
                    },
                    '&[data-bottom-line="true"]::before': {
                        position: 'absolute',
                        content: "''",
                        width: 30,
                        height: 2,
                        backgroundColor: '#337bbf',
                        left: 0,
                        bottom: -8
                    },
                    '&.anchor-link': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    },
                    "&[data-error]": {
                        color: "#ea4d54",
                        letterSpacing: 0,
                        lineHeight: 1.3,
                        fontSize: '0.875rem',
                    },
                    '&.backbtn': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#011352',
                    },
                    '&.text-ellipsis': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    position: 'relative',
                    fontWeight: 600,
                    borderRadius: 30,
                    padding: '8px 30px 7px',
                    '&.MuiButton-contained': {
                        background: 'linear-gradient(180deg, rgba(16,126,192,1) 0%, rgb(112 144 185) 85%)',
                        '&.Mui-disabled': {
                            color: '#fff',
                            opacity: 0.75
                        },
                        '&[data-gradient="false"]': {
                            background: '#337bbf'
                        }
                    },
                    '&.MuiButton-outlined': {
                        border: '1px dashed #D2D2D2',
                        '&:hover': {
                            borderColor: '#107dc0',
                            color: '#107dc0',
                            backgroundColor: '#eff9ff'
                        },
                        "&[btn-color='danger']": {
                            color: '#ea4d54',
                            '&:hover': {
                                borderColor: '#ea4d54',
                                backgroundColor: '#fdeeee'
                            },
                        }
                    },
                    '&[data-button-rounded="false"]': {
                        borderRadius: 6
                    },
                    '&[data-size="lg"]': {
                        padding: '12px 20px',
                        letterSpacing: 1.5
                    }
                },
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    marginTop: 0,
                    width: '100%',
                    marginBottom: 32,
                    '&.MuiFormControl-marginNormal': {
                        marginBottom: 12
                    },
                    '& .MuiFormControl-root': {
                        marginBottom: 0
                    },
                    '& .MuiInputBase-root': {
                        '&:before': {
                            borderBottom: '1px solid #eee'
                        },
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: '#ea4d54',
                        letterSpacing: 0,
                        lineHeight: 1.3,
                        position: 'absolute',
                        bottom: -20,
                        fontSize: 13
                    },
                    '& .MuiFilledInput-root.Mui-disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        border: '1px solid #d2d2d2',
                        borderRadius: 6
                    }
                },
            },
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    margin: 0
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControlLabel:
        {
            styleOverrides: {
                root: {
                    marginBottom: 0,
                    '& .MuiFormControlLabel-label': {
                        fontSize: 14,
                        color: '#586167',
                        padding: '8px 4px'
                    }
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-list': {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }
            }
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ddd',
                    '&.Mui-selected': {
                        backgroundColor: '#011352!important',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#011352',
                        }
                    }
                }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    '&[data-input-amount]': {
                        '& label[data-shrink="true"] + .MuiOutlinedInput-root::before, & .MuiOutlinedInput-root.Mui-focused::before': {
                            content: "'£'",
                            left: 10,
                            top: 10,
                            width: 10,
                            height: 10,
                            position: 'absolute',
                            display: 'block',
                            fontSize: 18,
                        },
                        '& .MuiOutlinedInput-root input': {
                            padding: '8.5px 14px 8.5px 28px'
                        }
                    }
                }
            }
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        flex: 1,
                        textTransform: 'none',
                        color: '#194242',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#194242',
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: '0',
                    '& .MuiPaper-root': {
                        padding: '6px 0',
                        backgroundColor: '#fff',
                        borderBottom: '1px solid',
                        borderColor: '#DADADA',
                        margin: 0,
                        '& .MuiAccordionSummary-root': {
                            padding: 0,
                            '& .MuiAccordionSummary-content': {
                                '& svg': {
                                    marginRight: 10
                                },
                                '&.Mui-expanded': {
                                    margin: '8px 0'
                                }
                            },
                            '&.Mui-expanded': {
                                minHeight: 45
                            }
                        },
                        '&::before': {
                            height: 0
                        }
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    color: '#194242',
                    '&.Mui-expanded': {
                        margin: 0
                    },
                    '& .MuiTypography-body1': {
                        fontSize: '0.875rem'
                    },
                    '& .MuiAccordionDetails-root': {
                        padding: 0,
                        '& div': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 0',
                            cursor: 'pointer'
                        },
                        '& .MuiTypography-body2': {
                            flex: 2,
                            marginLeft: 20
                        }
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'rgba(88, 97, 103, 0.7)!important',
                    '&:hover': {
                        color: '#107dc0!important'
                    }
                }
            }

        }
    },
});
