import { Navigate } from "react-router-dom";
import CommonServices from "../services/CommonServices";
const Protected = ({ children }) => {
    if (children.props.IsAuthenticate) {
        if (document.cookie) {
            return children;
        }
        else {
            return <Navigate to="/authenticate" replace />;
        }
    }
    if (!CommonServices.IsUserLoggedIn()) {
        return <Navigate to="/" replace />;
    }
    return children;
};
export default Protected;