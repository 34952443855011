import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from '../components/Loader';
import Protected from "./Protected";
//Site Route
const Authenticate = lazy(() => import('../access/Authenticate'));
const Home = lazy(() => import('../site/Home'));
const HowToWorks = lazy(() => import('../site/HowToWorks'));
const Faq = lazy(() => import('../site/Faq'));
const LendingRating = lazy(() => import('../site/LendingRating'));
const FactFigures = lazy(() => import('../site/FactFigures'));
const FainancialProduct = lazy(() => import('../site/FainancialProduct'));
const Services = lazy(() => import('../site/Services'));
const Press = lazy(() => import('../site/Press'));
const Blogs = lazy(() => import('../site/Blogs'));
const Career = lazy(() => import('../site/Career'));
const OurTeam = lazy(() => import('../site/OurTeam'));
const About = lazy(() => import('../site/About'));
const BridgingLoanEnquiry = lazy(() => import('../site/BridgingLoanEnquiry'));
//Investment
const Investments = lazy(() => import('../pages/investors/Investments'));
const FundDetails = lazy(() => import('../pages/investors/FundDetails'));
const InvestmentDetails = lazy(() => import('../pages/investors/InvestmentDetails'));
const FundWallet = lazy(() => import('../pages/investors/FundWallet'));
const InvestAmount = lazy(() => import('../pages/investors/InvestAmount'));
const Withdraw = lazy(() => import('../pages/investors/Withdraw'));
const SavingPlans = lazy(() => import('../pages/investors/savingplans'));
const PaymentDetails = lazy(() => import('../pages/investors/PaymentDetails'));
//Bond
const Bonds = lazy(() => import('../pages/bond/Bonds'));
const BondDetails = lazy(() => import('../pages/bond/BondDetails'));
//
const Login = lazy(() => import('../access/Login'));
//const Login2 = lazy(() => import('../access/Login2'));
const Register = lazy(() => import('../access/Register'));
const ForgotPassword = lazy(() => import('../access/ForgotPassword'));
const Dashboard = lazy(() => import('../pages/Home'));
const Borrower = lazy(() => import('../pages/borrower/Home'));
const Settings = lazy(() => import('../pages/Settings'));
const NotFound = lazy(() => import('../components/NotFound'));

export default function RoutesDefinition(props) {
    return (
        <Suspense fallback={<Loader allowLoader={true} />}>

            <Routes>
                {/* Site Routing */}
                <Route exact path="/authenticate" element={<Authenticate />} />
                <Route exact path="/" element={<Protected ><Home IsAuthenticate={true} /></Protected>} />
                <Route exact path="/howtoworks" element={<Protected ><HowToWorks IsAuthenticate={true} /></Protected>} />
                <Route exact path="/faq" element={<Protected ><Faq IsAuthenticate={true} /></Protected>} />
                <Route exact path="/LendingRating" element={<Protected><LendingRating IsAuthenticate={true} /></Protected>} />
                <Route exact path="/factfigures" element={<Protected><FactFigures IsAuthenticate={true} /></Protected>} />
                <Route exact path="/fainancialproduct" element={<Protected><FainancialProduct IsAuthenticate={true} /></Protected>} />
                <Route exact path="/services" element={<Protected><Services IsAuthenticate={true} /></Protected>} />
                <Route exact path="/about" element={<Protected><About IsAuthenticate={true} /></Protected>} />
                <Route exact path="/blogs" element={<Protected><Blogs IsAuthenticate={true} /></Protected>} />
                <Route exact path="/teams" element={<Protected><OurTeam IsAuthenticate={true} /></Protected>} />
                <Route exact path="/press" element={<Protected><Press IsAuthenticate={true} /></Protected>} />
                <Route exact path="/careers" element={<Protected><Career IsAuthenticate={true} /></Protected>} />
                <Route exact path="/loanenquiry" element={<Protected><BridgingLoanEnquiry IsAuthenticate={true} /></Protected>} />
                {/**/}
                <Route exact path="/login" element={<Login />} />
                {/* //<Route exact path="/login2" element={<Login2 />} /> */}
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/forgotpassword" element={<ForgotPassword />} />
                <Route exact path="/dashboard" element={<Protected><Dashboard /></Protected>} />
                <Route exact path="/borrow" element={<Protected><Borrower /></Protected>} />
                <Route exact path="/investments" element={<Protected><Investments /></Protected>} />
                <Route exact path="/funddetails" element={<Protected><FundDetails /></Protected>} />
                <Route exact path="/savingplans" element={<Protected><SavingPlans /></Protected>} />
                <Route exact path="/fundwallet" element={<Protected><FundWallet /></Protected>} />
                <Route exact path="/withdraw" element={<Protected><Withdraw /></Protected>} />
                <Route exact path="/paymentdetails" element={<Protected><PaymentDetails /></Protected>} />
                <Route exact path="/investments/investamount" element={<Protected><InvestAmount /></Protected>} />
                <Route exact path="/investments/investmentdetails/:id" element={<Protected><InvestmentDetails /></Protected>} />
                <Route exact path="/settings/:tab" element={<Protected><Settings /></Protected>} />
                <Route exact path="/bonds" element={<Protected><Bonds /></Protected>} />
                <Route exact path="/bonds/bonddetails/:id" element={<Protected><BondDetails /></Protected>} />
                <Route path='*' element={<NotFound />}></Route>
            </Routes>
        </Suspense>
    );
}